*{
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Gothic';
    src: url('../fonts/GOTHIC.TTF');
    font-weight: normal;
}

body{
    background: url("../image/beton_1980x1080.png");
    background-repeat: no-repeat;
    font-family: 'Gothic', sans-serif;
    background-size: cover;
    background-attachment: fixed;
}

/*** general class ***/
.uppercase-default{
    text-transform: uppercase;
}

/*** header ***/
.header__logo {
    margin-left: 8.17%;
    margin-top:34px;
    width: calc( (100vw - 375px)/(1920 - 375) * (634 - 166) + 166px);
    height: calc( (100vw - 375px)/(1920 - 375) * (150 - 38) + 38px);
}
.header__phone_number{
    display: none;
}
/*** end header ***/

@media only screen and (max-width: 480px) {
    header{
        background: #EF8B2D;
        margin-bottom: 36px;
        padding-bottom: 7px;
    }
    .header__number{
        /*position: absolute;*/
        color: #ffffff;
        line-height: 1em;
        font-size: 14px;
        text-decoration: none;
        letter-spacing: -1px;
        padding-bottom: 10px;
    }
    .header__phone_number{
        display: flex;
        justify-content: center;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        font-family: Raleway, sans-serif;
        margin: auto;
        text-align: center;
        padding-top:2px;
        height: 22px;
    }
    .header__phone_number > img {
        width: 16px;
        height: 16px;
    }

    .header__logo{
        /*display: block;*/
        margin-top: 0;
        margin-left: 20px;
    }

}


